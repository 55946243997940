import React from "react"
import { Box } from "@chakra-ui/react"
import ParseWpContent from "./ParseWpContent"

export const PostEntryContent = ({ data }) => {
  return data ? (
    <Box
      className="wsywyg"
      sx={{
        maxW: ["100%", "100%", "100%", "100%", "100%", "50vw"],
        img: {
          maxWidth: "100%!important",
        },
      }}
    >
      {ParseWpContent(data)}
    </Box>
  ) : (
    <></>
  )
}
